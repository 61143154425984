const validation = (args) => {
  let isValid = true;

  var validations = {
    isNotEmpty: function(val) {
      return val.trim() !== "";
    },
    isName: function(val) {
      var nameRegex = /^[a-zA-Záéíóúñ,. ]+$/;
      return nameRegex.test(val);
    },
    isEmail: function(val) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(val);
    },
    isInteger: function(val) {
      return parseInt(val) === Number(val);
    }
  };

  var validate = {
    names: validations.isName,
    email: validations.isEmail,
    numbers: validations.isInteger,
    required: validations.isNotEmpty
  };

  const showErrorMessage = (field) => {
    if (field.nextSibling) field.nextSibling.remove();
    const errorMessage = document.createElement('div');
    errorMessage.classList.add(args.errorMessageClass || 'error-message');
    errorMessage.innerText = 'Campo inválido';

    field.parentNode.insertBefore(errorMessage, field.nextSibling);
  }

  const isValidField = (field) => {
    const ruleArr = field.dataset.rules.split();
    
    return !ruleArr.some(rule => {
      const hasError = !validate[rule](field.value);
      if (hasError) {
        showErrorMessage(field);
        return true;
      } else {
        if (field.nextSibling) field.nextSibling.remove();
      }
    });
  }

  const init = () => {
    const form = document.querySelector(args.selector);
    const fields = form.querySelectorAll('[data-rules]');

    if (!fields.length) return;
    const fieldsArr = Array.prototype.map.call(fields, field => field);

    fieldsArr.forEach(field => {
      isValid = isValidField(field) && isValid;
    });
  }

  init();

  return {
    isValid, 
  }
}

export default validation;