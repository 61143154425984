export const getFilteredData = (data, value) => {
  if (!data) return;
  
  return data.filter(item => {
    const productWithBrand = item.attributes && item.attributes.find(attribute => attribute.id === 3);
    const productBrand = productWithBrand
      ? productWithBrand.options[0].substr(0, value.length).toLowerCase().trim()
      : false;
    
    const result = value.toLowerCase().trim() === item.name.substr(0, value.length).toLowerCase().trim()
      || value.toLowerCase().trim() === productBrand;

    return result;
  });
};
