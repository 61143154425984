import Glide from '@glidejs/glide'

const gallery = () => {
  const galleryWrapper = document.querySelector('.js-lm-gallery');

  if (!galleryWrapper) return;

  const links = galleryWrapper.querySelectorAll('a');

  Array.prototype.forEach.call(links, link => {
    link.href = '#!';
  });

  new Glide('.js-lm-gallery',{
    type: 'carousel',
    focusAt: 'center',  
    perView: 2,
    gap: 0,
    animationDuration: 300,
    breakpoints: {
      600: {
        perView: 1
      }
    }    
  }).mount();

  const singleGallery = document.querySelector('.woocommerce-product-gallery__wrapper');

  if (singleGallery) {
    const images = document.querySelectorAll('.woocommerce-product-gallery__image');

    Array.prototype.forEach.call(images, image => {
      image.onclick = (evt) => {
        evt.preventDefault();
        singleGallery.classList.toggle('active');
      }
    })
  }
}

export default gallery;