import axios from 'axios';
import validation from '../utils/validation';

const contactForm = () => {
  const selector = '.js-lm-contact-form';
  const form = document.querySelector(selector);
  const cta = document.querySelector('.js-lm-contact-cta');
  const notice = document.querySelector('.js-lm-contact-notice');

  if (!form) return;

  const arg = {
    selector,
    errorMessageClass: 'lm-field__error-message',
  }

  form.addEventListener('submit', evt => {
    evt.preventDefault();

    const data = {};

    Array.prototype.forEach.call(form, (el) => {
      if (el.name) {
        data[el.name] = el.value;
      }
    });

    if (validation(arg).isValid) {
      const buttonInner = cta.innerHTML;
      cta.disabled = true;
      cta.innerHTML = 'Enviando ...';

      axios.post('/send', data)
      .then(function (response) {
        cta.disabled = false;
        cta.innerHTML = buttonInner;
        notice.classList.add('is-visible')
      })
      .catch(function (error) {
        cta.disabled = false;
        cta.innerHTML = buttonInner;
        notice.classList.remove('is-visible')
      });      
    }
  })
}

export default contactForm;