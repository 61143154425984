const colorGallery = () => {
  const colorGallery = document.querySelector('.js-lm-hero-thumbnail');
  if (!colorGallery) return;
  
  const gallery = colorGallery.querySelector('.js-lm-hero-thumbnail-gallery');
  if (!gallery) return;

  
  const colorOptions = document.querySelectorAll('.js-lm-hero-thumbnail-color-option');
  const images = gallery.querySelectorAll('img');

  const changeImages = (evt) => {
    const color = evt.currentTarget.dataset.color;
    Array.prototype.forEach.call(images, (images) => {
      images.classList.remove('active');
    });

    gallery.querySelector(`.${color}`).classList.add('active');
  }

  Array.prototype.forEach.call(colorOptions, (option) => {
    option.addEventListener('click', changeImages);
  });
  
}

export default colorGallery;