import Glide from '@glidejs/glide';

function slider() {
  const heroSlider = document.querySelector('.js-hero-slider');

  if (heroSlider) {
    new Glide(heroSlider, {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      autoplay: 6000,
      hoverpause: false,
    }).mount();
  };
}

export default slider;
