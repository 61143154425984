import { getProductAttributes, getMedia } from '../utils/services';

function base() {
  const productAttributesData = window.localStorage.getItem('productAttributesData');
  if (!productAttributesData) {
    getProductAttributes().then(resp => {
      window.localStorage.setItem('productAttributesData', JSON.stringify(resp.data));
    });
  }
}

export default base;