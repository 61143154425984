const toggle = () => {
  const toggleElments = document.querySelectorAll('.js-toggle');
  Array.prototype.forEach.call(toggleElments, (el) => {
    el.addEventListener('click', () => {      
      const targetSelector = el.dataset.target;
      const target = document.querySelector(targetSelector);
      target.classList.toggle('is-visible');
    });
  });
}

export default toggle;