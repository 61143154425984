import { prefix } from '../constants/application';
import cmoHashNavigation from 'cmohashnavigation';

const nav = () => {
  const scrollNav = document.querySelector('.home .lm-menu--white');

  if (scrollNav) {
    cmoHashNavigation({
      selector: '.lm-menu--white',
      time: 600,
      easing: 'easeInOutQuad',
    });
  }

  const bodyEl = document.querySelector(`.js-${prefix}-body`);
  const navEl = document.querySelector(`.js-${prefix}-nav`);
  const hamburgerEl = document.querySelector(`.js-${prefix}-hamburger`);

  if (!navEl || !hamburgerEl) return;

  hamburgerEl.addEventListener('click', () => {
    bodyEl.classList.toggle(`mobile-nav-is-expanded`);
  });
};

export default nav;
