import validation from '../utils/validation';

const financing = () => {
  const isFinancingPage = document.querySelector('.js-financing');

  if (!isFinancingPage) return;
  const ctaFinancing = document.querySelector('.js-cta-financing');

  const getFinancingAmounts = (evt) => {
    evt.preventDefault();
    const selector = '.js-financing-form';

    const arg = {
      selector,
      errorMessageClass: 'lm-field__error-message',
    }

    if (validation(arg).isValid) {      
      const financingForm = document.querySelector('.js-financing-form');
      const financingResult = document.querySelector('.js-financing-result');
  
      const rate = 0.04;
      const financingPrice = +document.querySelector('.js-lm-financing-price').value;
      const financingInitial = +document.querySelector('.js-lm-financing-initial').value;

      if (+financingPrice < +financingInitial) return;
  
      const financingAmount = financingPrice - financingInitial;
      const financingFee = +document.querySelector('.js-lm-financing-fee').value;
      const financingFeeAmount = document.querySelector('.js-lm-financing-fee-amount');
      const totalFinancing = document.querySelector('.js-lm-financing-total-financing');
    
      if (!financingAmount || !financingFee || !financingInitial) return;
    
      financingFeeAmount.innerHTML = ((((financingAmount*rate)*financingFee)+financingAmount)/financingFee).toFixed(2);
      totalFinancing.innerHTML = financingAmount.toFixed(2);
  
      financingForm.style.display = 'none';
      financingResult.style.display = 'block';
    }


  }

  ctaFinancing.addEventListener("click", getFinancingAmounts);
}

export default financing;