import { getProductAttributes, getMedia } from '../utils/services';
import { getFilteredData } from '../utils/handles';
import modal from './modal';

const comparator = () => {
  if (!window.Vue) return;

  let productAttributesPromise = new Promise((resolve, reject) => {
    setInterval(function(){
      const productAttributesData = window.localStorage.getItem('productAttributesData');
      if (productAttributesData) resolve(JSON.parse(productAttributesData));
    }, 250);
  });

  let productsPromise = new Promise((resolve, reject) => {
    setInterval(function(){
      if (window.productsData) resolve(window.productsData);
    }, 250);
  });

  Vue.component('searchbox', {
    data: () => ({
      options: [],
      keyword: '',
    }),
    props: {
      updateProducts: {
        type: Function,
      },
      index: {
        type: Number,
      }
    },
    template: '<div class="lm-searchbox__wrapper">'+
              '<input class="input is-fullwidth" v-model="keyword" v-on:keyup="handleChange" />'+
              '<div class="lm-searchbox__options">'+
              '<div class="lm-searchbox__option" v-for="option in options" v-on:click="handleSelect" :data-index="index" :data-option="getJSON(option)">{{ option.name }}</div>'+
              '</div>'+
              '</div>',
    methods: {
      handleChange(e) {
        if (this.keyword.length < 3) {
          this.options = [];
          return;
        }

        this.options = getFilteredData(window.productsData, this.keyword);
      },
      handleSelect({ target: field }) {
        this.keyword = field.innerText;
        this.option = JSON.parse(field.dataset.option);
        this.options = [];
        this.updateProducts(this.option, +field.dataset.index);
      },
      getJSON(option) {
        return JSON.stringify(option);
      },
    },    
  });

  Vue.component('column', {
    props: {
      product: {
        type: Object,
        default: {
          attributes: [],
          images: [{ src:''}]
        }
      },
      attributes: {
        type: Array,
        default: []
      },
      loadAtachments: {
        type: Function
      }
    },
    updated() {
      if (this.product.name) {
        this.loadAtachments();
      }
    },
    template: '<div class="lm-comparator__column">'+
              '<div class="lm-comparator__attributes">'+
              '<div class="lm-comparator__attribute">{{ product.name || "-" }}</div>'+
              '<div class="lm-comparator__attribute" v-for="(attribute, index) in attributes">{{ getAttributesValue(attribute) }}</div>'+
              '</div>'+
              '</div>',
    methods: {
      getAttributesValue(attribute) {
        const attributeValue = this.product.attributes.find(attr => attr.name === attribute.name);
        return attributeValue ? attributeValue.options[0] : '-';
     
        if (!attributes) return;
        return attributes.filter(attribute => attribute.name !== 'Color');
      }
    }
  });

  Vue.component('searchboxes', {
    props: {
      products: {
        type: Array,
        default: []
      },
      attributes: {
        type: Array,
        default: []
      },
      updateProducts: {
        type: Function
      }
    },
    mounted() {
      modal();
    },
    updated() {
      modal();
    },
    template: '<div class="columns is-mobile is-marginless is-gapless">'+
              '<div v-for="(product, index) in products" class="column is-3">'+
              '<div class="lm-comparator__heading">'+
              '<div v-if="!product.name" class="lm-comparator__searchbox">'+
              '<h4>Agregar una moto para comparar</h4>'+
              '<searchbox :updateProducts="updateProducts" :index="index" />'+
              '</div>'+
              '<div v-if="product.name" class="lm-card">'+
              '<button class="lm-comparator__close" @click="clearColumn" :data-index="index">×</button>'+
              '<div class="lm-card__thumbnail" v-if="product.images && product.images[0]"><a :href="product.permalink" targte="_blank"><img :src="product.images[0].src" /></a></div>'+              
              '<div class="lm-card__title"><span>{{`${getBrand(product.attributes)} - ${product.name}`}}</span><div v-html="getColors(product.attributes)"></div></div>'+
              '<div class="lm-card__price"><span class="lm-card__label lm-card__label--sm">Precio</span><span>S/. {{ product.price }}</span></div>'+
              '<button class="lm-btn lm-btn--sm lm-btn--fluid js-lm-modal-open" data-modal=".js-lm-contact-modal"><i class="icon ion-md-checkmark-circle-outline"></i> Me la llevo</button>'+
              '</div>'+
              '</div>'+
              '</div>'+
              '</div>',
    methods: {
      clearColumn({ target: field }) {
        const attributes = this.attributes.map(attribute => '-');
        this.updateProducts({ attributes: attributes }, +field.dataset.index);
      },
      getBrand(attributes) {
        const brand = attributes.find(attribute => attribute.name.toLowerCase() === 'marca');
        if (!brand) return '-';
        return brand.options[0];
      },
      getColors(attributes) {
        if (!attributes.colors) return '-';
        const colors = attributes.find(attribute => attribute.name.toLowerCase() === 'color').options.map(color => `<span class="lm-card__color lm-card__color--${color.toLowerCase()}"></span>`);
        return `<span class="lm-card__label--sm">Colores</span> <div>${colors.join('')}</div>`;
      }
    }
  });

  Vue.component('columns', {
    data: () => ({
      attachments: []
    }),
    props: {
      products: {
        type: Array,
        default: []
      },
      attributes: {
        type: Array,
        default: []
      },      
    },
    template: '<div>'+
              '<div class="mb30">'+
              '<div class="columns is-mobile is-marginless is-gapless">'+
              '<div v-for="(product, index) in products" class="column is-3">'+
              '<column :product="product" :attributes="attributes" :loadAtachments="loadAtachments"/>'+
              '</div>'+
              '</div>'+
              '</div>'+
              '<div class="columns is-mobile">'+
              '<div v-for="(attachment, index) in attachments" class="column is-3 has-text-centered">'+
              '<a class="lm-btn-o lm-btn--sm lm-btn--fluid" target="_blank" :href="attachment"><i class="icon ion-md-download"></i> Ficha técnica</a>'+
              '</div>'+
              '</div>'+              
              '</div>',
    mounted() {
      this.loadAtachments();
    },
    methods: {
      async loadAtachments() {
        this.attachments = [];
        const products = [...this.products];
        
        for (let i = 0; i < products.length; i++) {
          if (!products[i].meta_data) return;
          const attachmentId = products[i].meta_data.find(meta => meta.key === 'ficha_tecnica').value;
          const { data: attachment } = await getMedia(attachmentId);
          this.getAttachments(attachment, i);
        }
      },
      getAttachments(attachment) {
        if (!attachment.guid) return false;
        this.attachments.push(attachment.guid.rendered);
        return true;
      },
    }
  });

  Vue.component('titles', {
    props: {
      products: {
        type: Array,
        default: []
      },
    },
    mounted() {
      const fixedTitle = document.querySelector('.lm-comparator__block__titles');
      const fixedTitlePos = fixedTitle.offsetTop;

      window.addEventListener('scroll', () => {
        if (window.pageYOffset > fixedTitlePos) {
          fixedTitle.classList.add('is-fixed');
        } else {
          fixedTitle.classList.remove('is-fixed');
        }
      })
    },
    template: '<div class="lm-comparator__block__titles">'+
      '<div class="container">'+
      '<div class="columns is-mobile is-gapless is-marginless">'+
      '<div class="column is-3 is-hidden-mobile"><div class="lm-comparator__attribute">Características</div></div>'+
      '<div class="column is-9">'+
      '<div class="columns is-mobile is-gapless is-marginless">'+
      '<div v-for="product in products" class="column is-3">'+
      '<div class="lm-comparator__attribute">{{ getTitleValue(product.attributes) }}</div>'+
      '</div>'+
      '</div>'+
      '</div>'+
      '</div>'+
      '</div>'+
      '</div>',
    methods: {
      getTitleValue(attributes) {
        const brand = attributes.find(attr => attr.name === 'marca');
        return brand ? brand.options[0] : '-';
      }
    }
  });

  const app = new Vue({
    el: '#app',
    data: () => ({
      title: 'Comparador',
      attributes: [],
      products: [],
    }),
    template: '<div class="lm-comparator" ref="content">'+
              '<div v-if="!products.length" class="lm-comparator__loading"></div>'+
              '<div v-if="products.length" class="lm-comparator__inner">'+
              '<div class="columns is-vcentered">'+
              '<div class="column is-3">'+
              '<button class="lm-btn lm-btn--sm lm-btn--fluid is-hidden-mobile" @click="download">Descargar comparación</button>'+
              '</div>'+
              '<div class="column is-9">'+
              '<searchboxes :products="products" :updateProducts="updateProducts" :attributes="attributes" />'+
              '</div>'+
              '</div>'+
              '<div class="lm-comparator__block">'+
              '<div class="lm-comparator__titles"><span class="lm-icon--description"></span>  Description</div>'+
              '<titles :products="products" />'+
              '</div>'+
              '<div class="columns is-mobile is-marginless is-gapless">'+
              '<div class="column is-3 lm-comparator__labels">'+
              '<div class="lm-comparator__attribute lm-comparator__attribute--label">Modelo</div>'+
              '<div class="lm-comparator__attribute lm-comparator__attribute--label" v-for="attribute in attributes"><span>{{ attribute.name }}</span></div>'+
              '</div>'+
              '<div class="column is-12-mobile is-9-desktop">'+
              '<columns :products="products" :attributes="attributes" />'+
              '</div>'+
              '</div>'+
              '</div>'+
              '</div>'+
              '</div>',
    mounted() {
      this.setAttributes();
    },
    methods: {
      async setAttributes() {
        // const { data } = await getProductAttributes();
        const productAttributes = await productAttributesPromise;
        const allProducts = await productsPromise;

        const order = [
          { slug: "pa_marca", order: 0 },
          { slug: "pa_color", order: 0 },
          { slug: "pa_origen-de-marca", order: 1 },
          { slug: "pa_pais-de-fabricacion", order: 2 },
          { slug: "pa_cilindrada", order: 3 },
          { slug: "pa_cilindros", order: 4 },
          { slug: "pa_diametro-por-carrera", order: 5 },
          { slug: "pa_ratio-de-compresion", order: 6 },
          { slug: "pa_torque-nm", order: 7 },
          { slug: "pa_torque-rpm", order: 8 },
          { slug: "pa_tipo", order: 9 },
          { slug: "pa_potencia", order: 10 },
          { slug: "pa_encendido", order: 11 },
          { slug: "pa_enfriamiento", order: 12 },
          { slug: "pa_transmision", order: 13 },
          { slug: "pa_n-de-cambios", order: 14 },
          { slug: "pa_velocidad", order: 15 },
          { slug: "pa_octanaje", order: 16 },
          { slug: "pa_sistema-de-combustible", order: 16 },
          { slug: "pa_capacidad-de-tanque-gl", order: 18 },
          { slug: "pa_rendimiento", order: 19 },
          { slug: "pa_autonomia-km", order: 20 },
          { slug: "pa_frenos", order: 21 },
          { slug: "pa_freno-delantero-detalle", order: 22 },
          { slug: "pa_frenos-traseros", order: 23 },
          { slug: "pa_freno-trasero-detalle", order: 24 },
          { slug: "pa_ancho-llanta-delantera", order: 25 },
          { slug: "pa_perfil-alto-llanta-delant", order: 26 },
          { slug: "pa_aro-delantero", order: 27 },
          { slug: "pa_tipo-de-aron-delantero", order: 28 },
          { slug: "pa_ancho-llanta-trasera", order: 29 },
          { slug: "pa_perfil-alto-llanta-trasera", order: 30 },
          { slug: "pa_aro-trasero", order: 31 },
          { slug: "pa_tipo-aro-trasero", order: 32 },
          { slug: "pa_suspension-delantera", order: 33 },
          { slug: "pa_suspension-trasera", order: 34 },
          { slug: "pa_distancia-piso", order: 35 },
          { slug: "pa_distancia-asiento-piso", order: 36 },
          { slug: "pa_distancia-entre-ejes", order: 37 },
          { slug: "pa_peso", order: 38 },
          { slug: "pa_carga-util-kg", order: 39 },
          { slug: "pa_largo-cm", order: 40 },
          { slug: "pa_ancho-cm", order: 41 },
          { slug: "pa_alto-cm", order: 42 },
          { slug: "pa_garantia", order: 43 },
          { slug: "pa_kilometraje-de-garantia", order: 44 },
          { slug: "pa_servicios", order: 45 },
        ];

        const orderedList = order.reduce((acc, item, index) => {
          const attr = productAttributes.find(attribute => attribute.slug === item.slug);
          acc.push(attr);
          return acc; 
        }, []);

        this.attributes = orderedList.filter(attribute => attribute.name !== 'Color').map(attribute => {
          switch (attribute.slug) {
            case 'pa_cilindrada':
              attribute.unit = 'cc';
              break;
            case 'pa_rendimiento':
              attribute.unit = 'km';
              break;
            case 'pa_velocidad':
              attribute.unit = 'km/h';
              break;
            case 'pa_peso':
              attribute.unit = 'kg';
              break;
            case 'pa_meses':
              attribute.unit = 'meses';
              break;
            case 'pa_potencia':
              attribute.unit = 'hp';
              break;
            default:
              attribute.unit = '';
          }

          return attribute;
        });
       
        const products = await allProducts.slice(0, 4);
        
        const param = window.location.search.split("=")[1];

        if (!param) {
          this.products = products;
        } else {
          const selectedProduct = allProducts.find(product => +product.id === +param);
          if (!selectedProduct) return;
          
          const defaultColumn = this.attributes.map(attribute => '-');
          const updatedProducts = products.map(product => ({ attributes: defaultColumn }));
          updatedProducts[0] = selectedProduct;
          
          this.products = updatedProducts;
        }
      },
      updateProducts(product, index) {
        const products = this.products.map(product => product);
        products[index] = product;
        this.products = products;
      },
      download() {
        const element = this.$refs.content;
        html2pdf().from(element).save();
      },
    }
  })
}

export default comparator;
