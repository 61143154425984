import noUiSlider from 'nouislider';

const priceSlider = () => { 
  const slider = document.querySelector('.js-price-slider');
  if (!slider) return;

  const input0 = document.querySelector('.js-lm-priceSlider-input-0');
  const input1 = document.querySelector('.js-lm-priceSlider-input-1');
  const inputs = [input0, input1];

  noUiSlider.create(slider, {
      connect: true,
      range: {
        'min': 0,
        '2%': 500,
        '4%': 1000,
        '6%': 1500,
        '8%': 2000,
        '10%': 2500,
        '12%': 3000,
        '14%': 3500,
        '16%': 4000,
        '18%': 4500,
        '20%': 5000,
        '22%': 5500,
        '24%': 6000,
        '26%': 6500,
        '28%': 7000,
        '30%': 7500,
        '32%': 8000,
        '34%': 8500,
        '36%': 9000,
        '38%': 9500,
        '40%': 10000,
        '42%': 10500,
        '44%': 11000,
        '46%': 11500,
        '48%': 12000,
        '50%': 12500,
        '52%': 13000,
        '54%': 13500,
        '56%': 14000,
        '58%': 14500,
        '60%': 15000,
        '62%': 15500,
        '64%': 16000,
        '66%': 16500,
        '68%': 17000,
        '70%': 17500,
        '72%': 18000,
        '74%': 18500,
        '76%': 19000,
        '78%': 19500,
        '80%': 20000,
        '82%': 20500,
        '84%': 21000,
        '86%': 21500,
        '88%': 22000,
        '90%': 22500,
        '92%': 23000,
        '94%': 23500,
        '96%': 24000,
        '98%': 24500,
        'max': 25000
    },
    snap: true,
    start: [0, 25000],
    cssPrefix: 'lm-priceSlider-'
  });

  const slideHandles = slider.querySelectorAll('.lm-priceSlider-handle');

  Array.prototype.forEach.call(slideHandles, handle => {
    const rangeLabel = document.createElement('div');
    rangeLabel.innerText = handle.getAttribute('aria-valuetext');
    rangeLabel.className = 'lm-priceSlider__label js-pricelider-label';
    handle.appendChild(rangeLabel);
  })

  slider.noUiSlider.on('update', function (values, handle) {
    inputs[handle].value = parseInt(values[handle]);
    slideHandles[handle].querySelector('.js-pricelider-label').innerText = `S/${parseInt(values[handle])}`;
  });
}

export default priceSlider;