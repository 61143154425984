// TODO: handle import routes with babel
import base from './base/index';
import slider from './components/slider';
import nav from './components/nav';
import form from './components/form';
import instagramGallery from './components/instagramGallery';
import priceSlider from './components/priceSlider';
import searchbox from './components/searchbox';
import contact from './components/contact';
import modal from './components/modal';
import toggle from './components/toggle';
import gallery from './components/gallery';
import colorGallery from './components/colorGallery';
import comparator from './components/comparator';
import financing from './components/financing';


document.addEventListener('DOMContentLoaded', () => {
  base();

  const cta = document.querySelector('.js-lm-open-pdf');
  const element = document.querySelector('.lm-product__attributes-details');

  if (!cta) return;

  cta.addEventListener('click', () => {
    html2pdf().from(element).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
    });

  })
    
});

window.addEventListener('load', () => {
  // form();
  slider();
  instagramGallery();
  colorGallery();
  nav();
  gallery();
  comparator();
  financing();
  priceSlider();
  searchbox();
  contact();
  modal();
  toggle();
});
