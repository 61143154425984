import { prefix } from '../constants/application';
import axios from 'axios';

const instagramGallery = async () => {
  const IG_API_URL = 'https://api.instagram.com/v1/users/self/media/recent/?count=6&access_token=26574576725.1677ed0.7e324cfc2208490cb2c8f6f55f2d91de';
  const instagramGalleryContainer = document.querySelector(`.js-${prefix}-instagramGallery-inner`);

  if (!instagramGalleryContainer) return;

  instagramGalleryContainer.classList.add('is-loading');
  const { data: { data: images } } = await axios.get(IG_API_URL);
  instagramGalleryContainer.classList.remove('is-loading');

  images.forEach((image) => {
    const imageLink = document.createElement('a');
    imageLink.href = image.link;
    imageLink.target = '_blank';
    imageLink.className = 'lm-instagramGallery__image';
    imageLink.innerHTML = `<img src=${image.images.standard_resolution.url} />`;
    instagramGalleryContainer.appendChild(imageLink);
  });
};

export default instagramGallery;
