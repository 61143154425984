import axios from 'axios';
import oauthSignature from 'oauth-signature';

const randomString = (length) => {
  let text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for(var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const getURL = (service, keyword = '', params) => {
  const httpMethod = 'GET';
  const baseURL = `https://lmotos.com/wp-json/wc/v3/${service}`;
  const parameters = {
      oauth_consumer_key : 'ck_17a949654b276dd309c2b03a16cd2f124ea351c0',
      oauth_timestamp : Math.floor(Date.now() / 1000),
      oauth_nonce : randomString(32),
      oauth_signature_method : 'HMAC-SHA1',
			oauth_version : '1.0',
			per_page: 100,
      search: keyword,
  };
  const consumerSecret = 'cs_2c564b103139eeca99bb1308118f894cc6817fad';
  const encodedSignature = oauthSignature.generate(httpMethod, baseURL, parameters, consumerSecret);

  let url = baseURL + "?oauth_version="+parameters.oauth_version+
    "&oauth_nonce="+parameters.oauth_nonce+
    "&oauth_timestamp="+parameters.oauth_timestamp+
    "&oauth_consumer_key="+parameters.oauth_consumer_key+
    "&oauth_signature_method="+parameters.oauth_signature_method+
		"&oauth_signature="+encodedSignature+
		"&per_page="+100+
    "&search="+parameters.search;

	return url;
};

export const getWPURL = (service, id = '') => {
    return `https://lmotos.com/wp-json/wp/v2/${service}/${id}`;
}

export const getProducts = (keyword) => axios.get(getURL('products', keyword, 'per_page=100'));
export const getProductAttributes = (keyword) => axios.get(getURL('products/attributes', keyword));
export const getBrands = () => axios.get(getURL('products/attributes/3/terms'));
export const getMedia = (id) => axios.get(getWPURL('media', id));