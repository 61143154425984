const modal = () => {
  const modals = document.querySelectorAll('.js-lm-modal');
  if (!modals) return;
  
  const getModalEl = (e) => {
    e.preventDefault();
    const modalSelector = e.currentTarget.dataset.modal;
    const modalEl = document.querySelector(modalSelector);
    return modalEl;
  }
  
  const handleListener = (buttons, action) => {
    if (!buttons.length) return;
    Array.prototype.forEach.call(buttons, (button => {
      button.addEventListener('click', (e) => {
        getModalEl(e).classList[action]('is-active');
      });
    }));    
  }

  const openButtons = document.querySelectorAll('.js-lm-modal-open');
  if (openButtons) handleListener(openButtons, 'add');
  
  const closeButtons = document.querySelectorAll('.js-lm-modal-close');
  if (closeButtons) handleListener(closeButtons, 'remove');
    
  const toggleButtons = document.querySelectorAll('.js-lm-modal-toggle');
  if (toggleButtons) handleListener(toggleButtons, 'toggle');
}

export default modal;