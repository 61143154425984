import axios from 'axios';
import validation from '../utils/validation';
import { getProducts, getBrands } from '../utils/services';
import { getFilteredData } from '../utils/handles';


const searchbox = () => {
  const form = document.querySelector('.js-lm-searchform');
  const results = document.querySelector('.js-lm-search-results');
  const input = document.querySelector('.js-lm-search-input');
  const maxInput = document.querySelector('.js-lm-search-max-input');
  const minInput = document.querySelector('.js-lm-search-min-input');

  if (!form) return;

  const getBrandName = (optionData) => {
    const productWithBrand = optionData.attributes && optionData.attributes.find(attribute => attribute.id === 3);
    return productWithBrand ? productWithBrand.options[0] : '';
  }

  const handleSelect = evt => {
    const { target: field } = evt;
    input.value = field.innerText;
    form.classList.add('is-valid');
    results.innerHTML = '';

    if (field.dataset.permalink) {
      location.href = field.dataset.permalink;
    }
  }
  
  const renderOption = (optionData, wrapper) => {
    const optionEl = document.createElement('div');
    optionEl.className = 'lm-searchbox__option';
    optionEl.innerText = `${getBrandName(optionData)} ${optionData.name}`;
    if (optionData.permalink) {
      optionEl.dataset.permalink = optionData.permalink;
    }
    optionEl.addEventListener('click', handleSelect);
    wrapper.appendChild(optionEl);
  }

  const renderOptions = (productsData, title) => {
    if (!productsData || !productsData.length) return;

    const wrapper = document.createElement('div');
    results.appendChild(wrapper);
    console.log('results', results);
    const titleEl = document.createElement('div');
    titleEl.className = 'lm-searchbox__option-title';
    titleEl.innerText = title;
    wrapper.appendChild(titleEl);

    productsData.forEach(optionData => {
      renderOption(optionData, wrapper);
    }); 
  }
  
  const getData = async (value) => {
    // const products = await getProducts(value);
    const productsData = getFilteredData(window.productsData, value);
    const brandsData = getFilteredData(window.brandsData, value);

    results.innerHTML = '';
    results.style.display = 'block';    
    
    // renderOptions(products.data, 'Productos');
    renderOptions(brandsData, 'Marcas');
    renderOptions(productsData, 'Modelos');
  }

  const handleChange = () => {
    input.addEventListener('blur', () => {
      setTimeout(() => {
        results.style.display = 'none';      
      }, 300);
    })
  }
  
  const saveOptions = async () => {
    window.productsData = JSON.parse(window.localStorage.getItem('productsData'));
    window.brandsData = JSON.parse(window.localStorage.getItem('brandsData'));

    if (!window.productsData) {
      const products = await getProducts();
      window.productsData = products.data;
      window.localStorage.setItem('productsData', JSON.stringify(window.productsData));
    }

    if (!window.brandsData) {
      const brands = await getBrands();
      window.brandsData = brands.data;
      window.localStorage.setItem('brandsData', JSON.stringify(window.brandsData));
    }

    handleChange();
  }

  input.addEventListener('keyup', (evt) => {
    evt.preventDefault();
    form.classList.remove('is-valid');
    
    const value = evt.target.value;
    if (value.length < 3) {
      results.innerHTML = '';        
    } else {
      getData(value);
    }
  });

  saveOptions();

  form.addEventListener('submit', evt => {
    const isBrand = window.brandsData.find(item => item.name.toLowerCase() === input.value.toLowerCase());

    if (!isBrand) {
      // evt.preventDefault();
      input.name = 's';
      // maxInput.name = '';
      // minInput.name = '';
    } else {
      console.log(input.value);
      form.submit();
    }
  })
}

export default searchbox;